import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import ImageModal from 'components/ui/image-modal';
import { authorKamil as author } from 'data/authors';

import img from 'img/blog/updates/website_options.png';

const Content = () => {
  return (
    <div>
      <p>
        You can now manage your website to disable the session recording temporarily. We have also
        released a domain validation for better security and to avoid recording sessions on the test
        environments.
      </p>
      <ImageModal src={img} alt="settings" title="Website settings" />
      <p className="mt-3">This options can be found in Settings &gt; Websites.</p>
    </div>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Website settings',
  url: '/blog/website-settings/',
  description: 'Temporary disable recording or add domain validation check for more security',
  author,
  img,
  date: '2019-04-17',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    group={frontmatter.group}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
  />
);
